import React, { FC } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import parseFaqCategory from '../utils/parseFaqCategory';

import { FaqCategoryProps } from './models';
import './FaqCategory.scss';

const FaqCategory: FC<FaqCategoryProps> = ({ value }) => (
  <div className="faq-category">
    <AnchorLink to={`#${parseFaqCategory(value)}`} title={value}>
      <span>{value}</span>
    </AnchorLink>
  </div>
);

export default FaqCategory;
